<template>
    <div class="search-form dflx aic">
      <input
        type="text"
        name="search"
        id="search"
        placeholder="Buscar"
        v-model="search"
        @keyup.enter="submit(search)"
      />
      <div class="btn-l" @click="submit(search)">
        <img src="../../assets/lupa.svg" alt="lupa">
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return { 
      search: "",
    };
  },
  methods: {
    submit(value) {
      return window.location.replace("/result/" + value);
    },
  },
}
</script>
<style >
.search-form {
    height: 10vw;
    width: auto;
    padding-bottom: 7vw;
}
.search-form input#search {
  padding: 2.899vw 2.899vw 2.415vw !important;
  width: 100%;
 border: none;
  color: #777;
  background-color: rgba(0, 0, 0, 0.05);
}
.btn-l {
    padding: 2vw 3vw;
    background: #00aeef;
}
.btn-l img{
    height: 4.348vw;
    width: 4.348vw;
    filter: brightness(3);
}
@media (min-width:768px) {
 
  .search-form input#search {
    width: 100%;
    padding: 0.899vw 1.899vw 0.915vw !important;
}  
}
</style>