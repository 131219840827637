<template>
  <div>
    <Navigator />
    <div class="searchl-c">
      <div class="searchList">
        <div class="list-sec">
          <div v-if="data !== 'No se encontraron coincidencias.'">
            <p>CATEGORÍA '{{ cat.name }}'</p>
            <div class="list-c" v-for="(d, i) in data" :key="i">
              <a :href="'/blog-post/' + d.url">
                <img :src="srcImg(d.image)" alt="" class="list-img" />
                <div class="list-info">
                  <p class="t">{{ d.title }}</p>
                  <p class="d">{{ d.dateblog }}</p>
                  <p class="desc">{{ d.descrp }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="val_no" v-else>
            NO SE ENCONTRARON RESULTADOS PARA '{{ this.$route.params.id }}'.
          </div>
        </div>
      </div>
      <div class="widget-c">
        <WebSMenu />
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Navigator from "../components/items-page/Navigator";
import dayjs from "dayjs";
let urlpath = require("../global/url");
import Footer from "../components/items-page/Footer";
import Search2 from "../components/items-page/Search2";
import WebSMenu from "../components/items-page/WebSMenu";

export default {
  components: {
    Navigator,
    Footer,
    WebSMenu,
    Search2,
  },
  async created() {
    let payload = {
      id: this.$route.params.id,
      option: "blog-by-category",
    };
    await this.getInfoByIdBlo(payload);
    let payload2 = {
      id: this.$route.params.id,
      option: "category",
    };
    await this.getInfoByIdCat2(payload2);
  },
  computed: {
    data() {
      let array = this.$store.getters["blog/data"];
      return array;
    },
    cat() {
      let array = this.$store.getters["category/data2"];
      return array;
    },
  },
  methods: {
    ...mapActions("blog", ["getInfoByIdBlo"]),
    ...mapActions("category", ["getInfoByIdCat2"]),
    srcImg: function (img) {
      let src = `${urlpath.url()}/blog-img/${img}`;
      return src;
    },
  },
};
</script>
<style>
.widget-c {
  display: none;
}
.searchList {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
}
.searchList p {
  margin: 0;
}
.searchList .titulo-sl {
  font-size: 4.348vw;
  line-height: 4.5vw;
  margin-bottom: 1.208vw !important;
  font-weight: 600;
  font-family: var(--RedHat);
}
p.desc-sl {
  font-size: 3.865vw;
  line-height: 4.15vw;
  margin-bottom: 3.623vw;
}
.list-sec .list-img {
  width: 14.493vw;
  height: 14.493vw;
  object-fit: cover;
  border-radius: 30vw;
}
.list-sec .list-c {
  width: 85%;
  border-bottom: 0.3vw solid gainsboro;
  padding-bottom: 10.87vw;
  margin: 7.246vw auto 10.87vw;
}
.list-sec .t {
  font-size: 4.348vw;
  line-height: 4.5vw;
  margin-bottom: 1.208vw !important;
  font-weight: 600;
  font-family: var(--RedHat);
  color: black;
  padding-top: 3.54vw;
}
.list-sec .d {
  font-size: 3.382vw;
  color: #222;
  font-weight: 400;
  line-height: 5.314vw;
  padding-bottom: 6.54vw;
}
.list-sec .desc {
  color: #222;
}
@media (min-width: 768px) {
  .widget-c {
    display: block;
    padding-left: 6.125vw;
  }
  .searchl-c {
    display: flex;
    margin: auto;
    width: fit-content;
  }
  .search-form {
    height: 3vw;
    padding-bottom: 0vw;
  }
  .searchList {
    width: 51.563vw;
  }
  .searchList .titulo-sl {
    font-size: 0.938vw;
    line-height: 1.1vw;
    margin-bottom: 0.508vw !important;
  }
  p.desc-sl {
    font-size: 0.833vw;
    line-height: 1.033vw;
    margin-bottom: 1.623vw;
  }
  .btn-l {
    padding: 0.7vw 0.81vw;
  }
  .btn-l img {
    height: 1.24vw;
    width: 1.24vw;
  }
  .list-info {
    padding-left: 1.6vw;
  }
  .list-sec .list-c {
    width: 100%;
    margin: 2.246vw auto 2.87vw;
    padding-bottom: 2.87vw;
    border-bottom: 0.1vw solid gainsboro;
  }
  .list-c a {
    display: flex;
  }
  .list-sec .list-img {
    width: 3.125vw;
    height: 3.125vw;
  }
  .list-sec .t {
    font-size: 0.938vw;
    line-height: 1.138vw;
    margin-bottom: 0.508vw !important;
    padding-top: 0;
  }
  .list-sec .d {
    font-size: 0.729vw;
    line-height: 0.929vw;
    padding-bottom: 1.54vw;
  }
  .search2-c {
    width: 30vw;
  }
}
</style>